import React from "react";

const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const UsersListing = React.lazy(() => import("./pages/users/UsersListing"));

const ServiceProvider = React.lazy(() =>
  import("./pages/sericeProviders/ServiceProvider")
);

const ServiceProviderDetail = React.lazy(() =>
  import("./pages/sericeProviders/Detail")
);

const VerifiedServiceProviderDetail = React.lazy(() =>
  import("./pages/sericeProviders/VerifiedDetail")
);

const AddServices = React.lazy(() => import("./pages/services/AddServices"));
const Categories = React.lazy(() => import("./pages/categories/Categories"));
const AddCategories = React.lazy(() =>
  import("./pages/categories/AddCategories")
);
const Service = React.lazy(() => import("./pages/services/Services"));
const ChangePassword = React.lazy(() => import("./pages/ChangePassword"));

const ActiveBooking = React.lazy(() =>
  import("./pages/Bookings/ActiveBooking")
);
const CompletedBookings = React.lazy(() =>
  import("./pages/Bookings/CompletedBooking")
);
const CancelledBookings = React.lazy(() =>
  import("./pages/Bookings/CancelledBooking")
);
const SingleBooking = React.lazy(() =>
  import("./pages/Bookings/SingleBooking")
);

const ViewChat = React.lazy(() => import("./pages/Bookings/ViewChat"));

const AddCoupon = React.lazy(() => import("./pages/Coupons/AddCoupon"));
const Coupons = React.lazy(() => import("./pages/Coupons/CouponList"));

const routes = [
  {
    path: "/addCoupons",
    exact: true,
    name: "Add Coupons",
    component: AddCoupon,
  },
  { path: "/coupons", exact: true, name: "Coupons", component: Coupons },
  { path: "/dashboard", exact: true, name: "Dashboard", component: Dashboard },
  { path: "/users", exact: true, name: "User", component: UsersListing },
  {
    path: "/serviceProvider",
    exact: true,
    name: "Service Provider",
    component: ServiceProvider,
  },
  {
    path: "/addServices",
    exact: true,
    name: "Add Services",
    component: AddServices,
  },

  {
    path: "/addCategories",
    exact: true,
    name: "Add Categories",
    component: AddCategories,
  },
  {
    path: "/categories",
    exact: true,
    name: "Categories",
    component: Categories,
  },
  {
    path: "/category/:serviceId",
    exact: true,
    name: "Service",
    component: Service,
  },
  {
    path: "/serviceProvider/:serviceProviderId",
    exact: true,
    name: "Service Provider",
    component: ServiceProviderDetail,
  },
  {
    path: "/verifiedServiceProvider/:serviceProviderId",
    exact: true,
    name: "Service Provider",
    component: VerifiedServiceProviderDetail,
  },

  {
    path: "/changePassword",
    exact: true,
    name: "Service Provider",
    component: ChangePassword,
  },
  {
    path: "/activeBookings",
    exact: true,
    name: "Active Bookings",
    component: ActiveBooking,
  },
  {
    path: "/completedBookings",
    exact: true,
    name: "Completed Bookings",
    component: CompletedBookings,
  },

  {
    path: "/cancelledBookings",
    exact: true,
    name: "Completed Bookings",
    component: CancelledBookings,
  },
  {
    path: "/booking/:bookingId",
    exact: true,
    name: "Single Bookings",
    component: SingleBooking,
  },

  {
    path: "/bookingChat/:bookingId/:subBookingId/:userId",
    exact: true,
    name: "view chat",
    component: ViewChat,
  },
];

export default routes;
