import toastr from "toastr";
import LoadingOverlay from "react-loading-overlay";
import styled, { css } from "styled-components";

export const baseUrl = "https://api.handysg.com/";
//export const baseUrl = "http://localhost:5000/";

export const awsBaseUrl = "https://handyproject.s3.us-west-2.amazonaws.com/";

toastr.options = {
  closeButton: true,
  debug: true,
  newestOnTop: false,
  progressBar: false,
  preventDuplicates: true,
  positionClass: "toast-top-right",
  preventDuplicates: true,
  showEasing: "swing",
  hideEasing: "linear",
  hideMethod: "fadeOut",
};

export const toastError = (msg) => {
  toastr.error(msg);
};
export const toastSuccess = (msg) => {
  toastr.success(msg);
};

const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${(props) =>
    props.disappear &&
    css`
      display: block; /* show */
    `}
`;

export const loading = (
  <DarkBackground disappear={!false}>
    <LoadingOverlay
      active={true}
      styles={{
        wrapper: {},
      }}
      spinner
      text="Loading your content..."
    />
  </DarkBackground>
);
