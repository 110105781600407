import React, { Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

import { loading } from "./utils/appConstants";

import Layout from "./pages/Layout";

//const Layout = React.lazy(() => import("./pages/Layout"));
const Login = React.lazy(() => import("./pages/Login"));

const history = createBrowserHistory();

function App() {
  let auth = localStorage.accessToken;

  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <Switch>
         
          {auth && (
            <Route
              path="/"
              name="Home"
              render={(props) => <Layout {...props} />}
            />
          )}
          {!auth && (
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
          )}
          {!auth && (
            <Route
              render={() => {
                history.push({ pathname: "/login" });
                history.go("/login");
              }}
            />
          )}
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
